import { SelectField, INotificationContext, Button } from '@livechat/design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Queries } from '../constants/queries';
import { useApiClient } from '../hooks/useApiClient';
import { SimpleCard } from '../../common/components/livechat/card/SimpleCard';
import { ErrorCard } from '../../common/components/livechat/ErrorCard';

export interface GroupSelectionCardProps {
  notificationSystem: INotificationContext;
}

export const GroupSelectionCard = ({ notificationSystem }: GroupSelectionCardProps) => {
  const [selectedItemKey, setSelectedItemKey] = useState<string | null>(null);

  const client = useApiClient();
  const queryClient = useQueryClient();

  const {
    data: organization,
    isLoading: organizationLoading,
    error: organizationError,
  } = useQuery(Queries.getOrganization, client.getOrganization);

  const {
    data: groups,
    isLoading: groupsLoading,
    error: groupsError,
  } = useQuery(Queries.getGroups, client.getGroups, { enabled: !!organization });

  const { mutate: setGroup, isLoading: setGroupLoading } = useMutation(client.setGroup, {
    onSuccess: () => {
      notificationSystem.add?.({
        type: 'toast',
        autoHideDelayTime: 5000,
        payload: {
          variant: 'success',
          content: 'Group was saved succesfully!',
          horizontalPosition: 'center',
          verticalPosition: 'top',
          removable: false,
        },
      });
      queryClient.invalidateQueries(Queries.getOrganization);
    },
  });

  const items = useMemo(() => {
    return groups?.items?.map(g => ({ key: g.id.toString(), props: { name: g.name, value: g.id } }));
  }, [groups]);

  const selectedGroupId = useMemo(() => {
    const item = items?.find(i => i.key === selectedItemKey);
    return item?.props?.value ?? null;
  }, [items, selectedItemKey]);

  useEffect(() => {
    if (!organizationLoading && !groupsLoading && organization && items) {
      const key = items.find(i => i.props.value === organization?.livechat?.routeToGroupId)?.key ?? null;
      setSelectedItemKey(key);
    }
  }, [groupsLoading, organizationLoading, organization, items]);

  const handleItemSelect = useCallback((key: string) => {
    if (!key) {
      setSelectedItemKey(null);
    } else {
      setSelectedItemKey(key);
    }
  }, []);

  const getItemBody = useCallback((props: { value: string; name: string }) => {
    if (!props) {
      return null;
    }
    return <div id={props.value}>{props.name}</div>;
  }, []);

  const getSelectedItemBody = useCallback((props: { value: number; name: string }) => {
    return <div id={props.value.toString()}>{props.name}</div>;
  }, []);

  if (groupsLoading || organizationLoading) {
    return <SimpleCard title="Group Selection" loading info={<></>} footer={<></>}></SimpleCard>;
  }

  if (organizationError) {
    return <ErrorCard error="Unable to load organization" />;
  }

  if (groupsError) {
    return <ErrorCard error="Unable to load groups" />;
  }

  const info = (
    <>
      <SelectField
        id="Group"
        items={items || []}
        searchProperty="name"
        onItemSelect={handleItemSelect}
        getItemBody={getItemBody}
        labelText="Select group"
        description='If left empty, defaults to "General"'
        search
        placeholder="Select group"
        getSelectedItemBody={getSelectedItemBody}
        selected={selectedItemKey || ''}
        searchPlaceholder="Search by name..."
        selectHeader="Select a group to route messages to"
      />
    </>
  );

  const footer = (
    <Button
      loading={setGroupLoading}
      kind="primary"
      onClick={() => {
        setGroup(selectedGroupId ?? null);
      }}
    >
      Save
    </Button>
  );

  return <SimpleCard title="Group Selection" info={info} footer={footer}></SimpleCard>;
};
