import {
  Card,
  Loader,
  Divider,
  notificationConnect,
  INotificationContext,
  Button,
  Select,
  NotificationProvider,
  ToastConsumer,
} from '@livechat/design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Table, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import { ErrorCard } from '../../common/components/livechat/ErrorCard';
import { LoaderContainer } from '../../common/components/livechat/Loader';
import { useLivechat } from '../../common/hooks/useLivechat';
import { GoogleAccountListItemDto } from '../api/client';
import { Queries } from '../constants/queries';
import { useApiClient } from '../hooks/useApiClient';
import 'semantic-ui-css/semantic.min.css';

const Link = styled.a`
  text-decoration: none;

  &:visited,
  &:hover {
    text-decoration: none;
    color: #4384f5;
  }
`;

interface ButtonWithToastProps extends INotificationContext {
  notificationSystem: INotificationContext;
  children?: any;
  onClick: () => Promise<void>;
}

const ButtonWithToast = ({ notificationSystem, children, onClick }: ButtonWithToastProps) => {
  const onny = () => {
    onClick().then(() => {
      notificationSystem.add?.({
        autoHideDelayTime: 3000,
        type: 'toast',
        payload: {
          variant: 'success',
          content: 'Group is saved successfully!',
          horizontalPosition: 'center',
          verticalPosition: 'top',
          removable: true,
        },
      });
    });
  };

  return (
    <Button kind="primary" onClick={onny}>
      {children}
    </Button>
  );
};

export const Settings = () => {
  const [APIData, setAPIData] = useState<GoogleAccountListItemDto[]>([]);
  const {
    error: loginError,
    loading: loginLoading,
    loggedIn,
  } = useLivechat({
    loginUrl: '/api/livechat-google-my-business/connect/livechat',
  });

  const client = useApiClient();

  const openInNewTab = (url: string | URL | undefined) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const ToastedButton = notificationConnect(ButtonWithToast as never) as any;

  useEffect(() => {
    client.listGoogleAccounts().then(response => {
      console.log('List google accounts response data in useEffect', response);
      setAPIData(response);
    });
    // eslint-disable-next-line
  }, []);

  const {
    data: organization,
    isLoading: organizationLoading,
    error: organizationError,
  } = useQuery(Queries.getOrganization, client.getOrganization, { enabled: loggedIn });

  const {
    data: groups,
    // isLoading: groupsLoading,
    // error: groupsError,
  } = useQuery(Queries.getGroups, client.getGroups, { enabled: !!organization });

  const updateGroup = async (routeToGroupId: number | null, agentId: string): Promise<void> => {
    await client.setGroupByAgentId(routeToGroupId, agentId);
    client.listGoogleAccounts().then(response => {
      setAPIData(response);
    });
  };

  const items = useMemo(() => {
    return groups?.items?.map(g => ({ key: g.id.toString(), props: { name: g.name, value: g.id } }));
  }, [groups]);

  const handleItemSelect = (index: number) => {
    console.log('1st is called with index', index);
    return (key: string) => {
      console.log('2nd is called with key', key);
      console.log('APIData before', APIData);
      const copy = APIData.slice();
      copy[index] = {
        ...copy[index],
        routeToGroupId: !key ? 0 : parseInt(key),
      };
      console.log('APIData after', APIData);
      setAPIData(copy);
      console.log('after setAPIData', APIData);
    };
  };

  const getItemBody = useCallback((props: { value: string; name: string }) => {
    if (!props) {
      return null;
    }
    return <div id={props.value}>{props.name}</div>;
  }, []);

  const getSelectedItemBody = useCallback((props: { value: number; name: string }) => {
    return <div id={props.value.toString()}>{props.name}</div>;
  }, []);

  if (loginError) {
    return <ErrorCard error="Login failed" />;
  }

  if (loginLoading || organizationLoading) {
    return (
      <LoaderContainer>
        <Loader size="large" />
      </LoaderContainer>
    );
  }

  if (organizationError || !organization) {
    return <ErrorCard error="Organization error" />;
  }

  return (
    <NotificationProvider>
      <ToastConsumer horizontalPosition="center" fixed verticalPosition="top" />

      <Card
        style={{
          overflow: 'visible',
          marginTop: '0px',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          borderWidth: 0,
        }}
        title={'Google My Business Settings'}
        img={'https://cdn.worldvectorlogo.com/logos/google-my-business-logo.svg'}
      >
        <div>
          <p>Thanks for installing Google Business Messages Application!</p>
          <Divider />
          <h3>Google Business Messages Verification</h3>
          <p>
            Integration requires a verification from Google to integrate your Google My Business Account to LiveChat.
          </p>
          <p>
            Please click "+ Connect Google Business Profile" button to fill out the form to start the application
            process.{' '}
          </p>
          <p> </p>
          <div>
            {APIData && APIData.length > 0 ? (
              <Table singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Agent</Table.HeaderCell>
                    <Table.HeaderCell>Routing Group</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    {/* <Table.HeaderCell>Settings</Table.HeaderCell> */}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {APIData.map((data: GoogleAccountListItemDto, index: number) => {
                    return (
                      <Table.Row key={data.agentId}>
                        <Table.Cell>
                          <Image src={data.agentLogoUrl} avatar spaced />
                          <b>{data.agentName}</b>
                        </Table.Cell>
                        <Table.Cell>
                          <div
                            style={{
                              width: '400px',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <div
                              style={{
                                width: '300px',
                              }}
                            >
                              <Select
                                id="Group"
                                items={items || []}
                                searchProperty="name"
                                onItemSelect={handleItemSelect(index)}
                                getItemBody={getItemBody}
                                // labelText="Forward group:"
                                // description='If left empty, defaults to "General"'
                                search
                                required
                                placeholder="Select routing group"
                                getSelectedItemBody={getSelectedItemBody}
                                selected={data.routeToGroupId.toString() || ''}
                                searchPlaceholder="Search by name..."
                                selectHeader="Select a group to route messages to"
                              />
                            </div>
                            <div>
                              <ToastedButton
                                onClick={() => {
                                  return updateGroup(data.routeToGroupId, data.agentId);
                                }}
                              >
                                Save Group
                              </ToastedButton>
                            </div>
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          <b>{data.status}</b>
                        </Table.Cell>
                        {/* <Table.Cell>
                        { <Link to="update-agent">
                      <Button onClick={() => {}}>Update</Button>
                    </Link> }
                      </Table.Cell> */}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="4">
                      {/* <Link to="/create"> */}
                      <Button
                        fullWidth
                        kind="primary"
                        onClick={() => {
                          openInNewTab('https://forms.gle/D6HWJ9HybDcvrBfo8');
                        }}
                      >
                        + Connect Google Business Profile
                      </Button>
                      {/* </Link> */}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            ) : (
              <Button
                fullWidth
                kind="primary"
                onClick={() => {
                  openInNewTab('https://forms.gle/D6HWJ9HybDcvrBfo8');
                }}
              >
                + Connect Google Business Profile
              </Button>
            )}
          </div>
          <br />
          <p>
            For support contact our team at:{' '}
            <Link target="_blank" href="mailto:support@octochat.io">
              support@octochat.io.
            </Link>
          </p>
        </div>
      </Card>
    </NotificationProvider>
  );
};
