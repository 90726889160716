import { Card, Divider, Loader } from '@livechat/design-system';
import React from 'react';
import { CardContainer } from './CardContainer';
import { Info } from './Info';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  height: 175px;
  display: flex;
  justify-content: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export interface SimpleCardProps {
  info: React.ReactNode;
  footer?: React.ReactNode;
  title: string;
  loading?: boolean;
  image?: string;
}

export const SimpleCard = ({ info, footer, title, loading, image }: SimpleCardProps) => {
  if (loading) {
    return (
      <CardContainer>
        <Card style={{ overflow: 'visible', marginTop: '0px', flex: 1, display: 'flex', flexDirection: 'column' }} title={title} img={image}>
          <LoaderContainer>
            <Loader size="medium" />
          </LoaderContainer>
        </Card>
      </CardContainer>
    );
  }

  return (
    <CardContainer>
      <Card style={{ overflow: 'visible', marginTop: '0px', flex: 1, display: 'flex', flexDirection: 'column' }} title={title} img={image}>
        <CardContent>
          <Info>{info}</Info>
          <Spacer />
          {footer && (
            <>
              <Divider />
              {footer}
            </>
          )}
        </CardContent>
      </Card>
    </CardContainer>
  );
};
