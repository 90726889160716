import { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

export const useLivechat = ({ loginUrl }: { loginUrl: string }) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [status, setStatus] = useQueryParam('status', StringParam);
  const [loading, setLoading] = useState<boolean>(!!status || !loggedIn);

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (loggedIn) return;
    switch (status) {
      case 'success': {
        setLoggedIn(true);
        setLoading(false);
        setError(null);
        break;
      }
      case 'failure': {
        setError('Login unsuccessful');
        break;
      }
      case undefined: {
        setError(null);
        window.location.replace(loginUrl);
        break;
      }
      default: {
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (loggedIn) {
      setLoading(false);
      setStatus(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return {
    loading,
    error,
    loggedIn,
  };
};
