import { Button } from '@livechat/design-system';
import React from 'react';
import { SimpleCard } from './card/SimpleCard';
import styled from 'styled-components';

const Text = styled.p`
  margin: 0 auto;
  font-size: 14px;
`;

const Link = styled.a`
  text-decoration: none;

  &:visited,
  &:hover {
    text-decoration: none;
    color: #4384f5;
  }
`;
export const SupportCard = () => {
  const info = (
    <>
      <Text>
        For requests, please contact our support team at{' '}
        <Link target="_blank" href="mailto:support@octochat.io">
          support@octochat.io.
        </Link>
      </Text>
    </>
  );

  const footer = (
    <Button
      kind="secondary"
      onClick={() => {
        window.open('mailto:support@octochat.io');
      }}
    >
      Send Email
    </Button>
  );

  return <SimpleCard title="Support" info={info} footer={footer}></SimpleCard>;
};
