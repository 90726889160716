import { Button } from '@livechat/design-system';
import { SimpleCard } from './card/SimpleCard';

export const ErrorCard = ({ error }: { error: string }) => {
  const info = (
    <>
      <p>An unexpected error has occured ({error}). Please contact our support team at </p>
      <a target="_blank" rel="noreferrer" href="mailto:support@octochat.io">
        support@octochat.io.
      </a>
    </>
  );

  const footer = (
    <Button
      onClick={() => {
        window.open('mailto:support@octochat.io');
      }}
    >
      Send Email
    </Button>
  );

  return <SimpleCard title="Error" info={info} footer={footer}></SimpleCard>;
};
