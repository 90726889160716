import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { Home } from './common/pages/Home';
import { config } from './config';
import { Settings as LiveChatGoogleBusinessMessagesSettings } from './livechat-google-my-business/pages/OzSettings';
import { Settings as LivechatTwitterCommentsSettings } from './livechat-twitter-comments/pages/Settings';

console.log('Using config', config);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 30 * 5 * 1000,
      retry: false,
      staleTime: 30 * 5 * 1000,
    },
  },
});

export const App = () => {
  return (
    <QueryParamProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/livechat-google-my-business/settings" element={<LiveChatGoogleBusinessMessagesSettings />} />
            <Route path="/livechat-twitter-tweets/settings" element={<LivechatTwitterCommentsSettings />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </QueryParamProvider>
  );
};
