import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 300px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
`;
