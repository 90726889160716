import axios from 'axios';

export interface OrganizationDto {
  id: string;
  status: 'LIVECHAT_READY' | 'INTEGRATED';
  livechat: {
    routeToGroupId: number | null;
  } | null;
}

export interface Group {
  id: number;
  name: string;
}

export interface GroupsResponse {
  items: Group[];
}

export interface GoogleAccountListItemDto {
  agentId: string;
  agentName: string;
  agentLogoUrl: string;
  routeToGroupId: number;
  status: string;
}

export const createApiClient = () => {
  const api = axios.create({
    baseURL: '/api/livechat-google-my-business',
    withCredentials: true,
  });

  api.interceptors.response.use(
    response => response.data,
    error => Promise.reject(error)
  );

  const getOrganization = () => api.get<OrganizationDto, OrganizationDto>('/organization');

  const listGoogleAccounts = () => api.get<GoogleAccountListItemDto[], GoogleAccountListItemDto[]>(`/list-google-accounts`);

  const getGroups = () => api.get<GroupsResponse, GroupsResponse>('/groups');

  const setGroup = (routeToGroupId: number | null) => api.put('/set-route-group', { routeToGroupId });

  const setGroupByAgentId = (routeToGroupId: number | null, agentId: string) => api.put('/set-route-group', {routeToGroupId, agentId});

  return { 
    getOrganization,
    listGoogleAccounts,
    getGroups,
    setGroup,
    setGroupByAgentId,
   };
};
