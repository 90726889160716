import { Card, Divider } from '@livechat/design-system';
import '@livechat/design-system/dist/design-system.css';

export const Home = () => {
  return (
        <Card
          style={{ overflow: 'visible', marginTop: '0px', flex: 1, display: 'flex', flexDirection: 'column' }}
          title={'Octo Flow Home'}
          img={''}
        >
          <div>
            <Divider />
            <p>This is the home page for Octo Flow LiveChat Applications</p>
          </div>
        </Card>
  );
};
