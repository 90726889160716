import axios from 'axios';

export interface OrganizationDto {
  id: string;
  status: 'LIVECHAT_READY' | 'INTEGRATED';
  livechat: {
    routeToGroupId: number | null;
  } | null;
  twitter: {
    username: string | null;
  } | null;
}

export interface Group {
  id: number;
  name: string;
}

export interface GroupsResponse {
  items: Group[];
}

export const createApiClient = () => {
  const api = axios.create({
    baseURL: '/api/livechat-twitter-tweets',
    withCredentials: true,
  });

  api.interceptors.response.use(
    response => response.data,
    error => Promise.reject(error)
  );

  const getOrganization = () => api.get<OrganizationDto, OrganizationDto>('/organization');

  const getGroups = () => api.get<GroupsResponse, GroupsResponse>('/groups');

  const setGroup = (routeToGroupId: number | null) => api.put('/set-route-group', { routeToGroupId });

  const deleteTwitter = () => api.delete('/disconnect-twitter');

  return { getOrganization, getGroups, setGroup, deleteTwitter };
};
